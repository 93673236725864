@import "~react-image-gallery/styles/css/image-gallery.css";

button {
  cursor: pointer;
  outline: none;
  border: none;
}

.logo-font {
  font-family: "Oswald", sans-serif;
}

.font-heading {
  font-family: "Teko", sans-serif;
  font-weight: bold;
}

.main {
  width: 100%;
  height: 100vh;
  position: relative;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  height: 100%;
}

.navbar {
  width: 100%;
  height: 80px;
  position: relative;
  /* z-index: 2; */
}

.content {
  width: 100%;
  min-height: calc(100% - 80px);
  position: relative;
  z-index: 1;
  margin: 0 auto;
}

.spinner-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.image-gallery .focus-visible {
  outline: none !important;
  border: 0 !important;
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 480px) {
  .imgContainer {
    width: calc(100% - 12px);
  }
}
