.container {
  width: 100%;
  padding-bottom: 24px;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  min-height: 500px;
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px -20px 70px -2px black;
  z-index: 1px;
}

.titleContainer {
  width: 100%;
  position: relative;
  z-index: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1240px;
}

.leftContainer {
  flex: 1;
  max-width: 500px;
}

.title {
  color: white;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 3rem;
}

.authorName {
  color: #f0f0f0;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 2rem;
}

.bookButton {
  background: #ff6b5a;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Montserrat";
  font-size: 2rem;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.5);
}

.agendaContainer,
.timingsContainer,
.availabilityContainer,
.bookButtonContainer {
  max-width: 1340px;
  margin: 0 auto;
}

.heading {
  font-size: 2rem;
  font-weight: bold;
  margin: 32px 0 8px 0;
  font-family: "Poppins";
  display: flex;
  align-items: center;
  color: #464646;
  fill: #464646;
  & span {
    margin-left: 8px;
  }
}

.description,
.timings,
.availability {
  font-size: 1.6rem;
  color: #292929;
  font-family: "Montserrat";
  line-height: 2.5rem;
}

.timings span {
  font-weight: bold;
}

.bookButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
}

@media only screen and (max-width: 480px) {
  .banner {
    min-height: 400px;
  }
  .titleContainer {
    flex-direction: column;
    text-align: center;
    padding: 0 16px;
  }
  .rightContainer {
    padding-top: 16px;
  }
  .agendaContainer,
  .timingsContainer,
  .availabilityContainer {
    padding: 0 16px;
  }
}
