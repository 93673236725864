.main {
    width: 100%;
}

.title {
    font-weight: bold;
    color: var(--color-gray-7);
    padding-top: 20px;
    padding-bottom: 10px;
}

.imgsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.imgContainer {
    width: calc(33.33% - 12px);
    margin: 0 5px;
    height: 240px;
    position: relative;
    overflow: hidden;
    border: 1px solid var(--color-gray-2);
    cursor: pointer;
    margin-bottom: 10px;
}

.imgContainer .img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: center center;
    z-index: 1;
    transition: all ease-in-out 0.2s;
}

.imgContainer:hover .img {
    transform: scale(1.1);
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    opacity: 0;
    transition: all ease-in-out 0.2s;
}

.view {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    z-index: 3;
    opacity: 0;
    transition: all ease-in-out 0.2s;
}

.imgContainer:hover .overlay {
    opacity: 1;
}

.imgContainer:hover .view {
    opacity: 1;
}

@media only screen and (max-width: 1024px) {

    .title {
        padding-top: 20px;
        padding-bottom: 10px;
        padding-left: 5px;
        padding-right: 5px;
    }

}

@media only screen and (max-width: 768px) {

    .title {
        padding-top: 20px;
        padding-bottom: 10px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .imgContainer {
        height: 210px;
    }

}

@media only screen and (max-width: 480px) {

    .imgContainer {
        width: calc(100% - 12px);
        height: 240px;
    }

}