.main {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  perspective: 1000px;
  box-shadow: 0px 2px 5px -3px rgba(0, 0, 0, 0.5);
}

.main > img {
  width: 100%;
  display: block;
  transition: all ease-in-out 0.2s;
}

.main:hover img {
  transform: scale(1.1) rotateX(7deg);
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  opacity: 0;
  transition: all ease-in-out 0.2s;
}

.view {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 3;
  opacity: 0;
  transition: all ease-in-out 0.2s;
}

.main:hover .overlay {
  opacity: 1;
}

.main:hover .view {
  opacity: 1;
}
