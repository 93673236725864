.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.wrapper img {
  width: 20%;
}

.wrapper .emptyText {
  width: 100%;
  text-align: center;
  color: var(--color-gray-4);
  padding-top: 15px;
  font-size: 0.9rem;
}

.wrapper .shoppingBtn {
  width: 100%;
  padding-top: 15px;
  text-align: center;
}

.wrapper .emptyText,
.wrapper .shoppingBtn {
  margin-left: 25px;
}

.wrapper .shoppingBtn a {
  border: none;
  outline: none;
  padding: 8px 32px;
  font-family: "Quicksand";
  text-transform: uppercase;
  background: var(--color-blue);
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;
  text-decoration: none;
}

.wrapper .shoppingBtn a:hover {
  background: var(--color-primary);
}
