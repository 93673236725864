.container {
    width: 100%;
    min-height: 100%;
    padding-bottom: 12px;
}

.title {
    font-family: "Teko", sans-serif;
    font-weight: bold;
    font-size: 2.4rem;
    text-align: center;
    margin-top: 32px;
}

.bannerContainer img {
    width: 100%;
    display: block;
    margin-top: 32px;
}

.row {
    display: flex;
}

.column {
    flex: 1;
}

.aboutContainer {
    padding: 0 80px;
}

.aboutTitle {
    font-size: 1.8rem;
    /* font-family: "Teko", sans-serif; */
    font-weight: bold;
    text-align: center;
    margin-top: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 12px;
}

.workshopAbout {
    text-align: justify;
    text-align: center;
    padding: 0 40px;
}

.date {
    text-align: center;
    border-bottom: none;
    margin-bottom: 4px;
    padding-bottom: 0;
    background-color: var(--color-blue);
    color: white;
    padding: 20px;
    position: relative;
}
/* .date::after {
    content: "Timings";
    position: absolute;
    left: 14px;
    top: 8px;
    font-size: 1rem;
    font-weight: normal;
    color: var(--color-primary);
    background-color: var(--color-blue);
    padding: 0 2px;
} */

.purchaseFor {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    margin-top: 4px;
}

.timings {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bookButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}

.bookButton {
    background-color: #e64343;
    color: white;
    text-decoration: none;
    border-radius: 8px;
    padding: 8px 12px;
    cursor: pointer;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
    transition: all ease-in-out 0.2s;
}
.bookButton:hover {
    box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.6);
}

@media only screen and (max-width: 480px) {
    .bannerContainer {
        padding: 0 8px;
    }
    .aboutContainer {
        padding: 0 8px;
    }
    .workshopAbout {
        padding: 0 10px;
    }
}