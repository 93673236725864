.main {
}

.slidercontainer {
    height: 300px;
}

.formContainer {
    font-size: 16px;
    text-align: center;
    padding-bottom: 20px;
    width: 100%;
}

.formContainer label {
    display: block;
    margin-top: 12px;
}

.formContainer label::after {
    content: "*";
    color: red;
}

.formContainer * {
    width: 80%;
}

.formContainer textarea {
    height: 100px;
}

.formContainer button {
    background-color: tomato;
    width: 20%;
    font-weight: bold;
    padding: 12px;
    color: white;
    border-radius: 4px;
    margin-top: 12px;
}

.buynow {
    width: 200px;
    background-color: #E19100;
    text-align: center;
    font-weight: 800;
    padding: 11px 0px;
    color: white;
    font-size: 12px;
    display: inline-block;
    text-decoration: none;
}

form {
    text-align: center;
}

.productDetails {
    font-size: 16px;
    padding: 0px 12px;
}

.productDetails .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 4px;
}

.productDetails .last {
    margin-top: 4px;
}

.keyValue {
    padding: 2px 0;
}

.key {
}

.value {
}