.main {
  width: 100%;
  padding: 10px 0;
  position: relative;
  font-size: 0.9rem;
}

.main ul {
}

.main ul li {
  width: 100%;
  text-align: center;
  /* padding: 5px 0; */
  margin: 0;
}

.main ul li a {
  display: block;
  width: 100%;
  padding: 5px 0;
}

.main .arrow {
  position: absolute;
  width: 15px;
  height: 15px;
  background: white;
  z-index: 2;
  /* left: 50%; */
  right: 7.5px;
  transform: rotateZ(45deg);
  top: -7px;
  border: 1px solid var(--color-gray-2);
}

.main .mask {
  position: absolute;
  width: 100%;
  height: 15px;
  z-index: 3;
  left: 0;
  top: 0;
  background: white;
}

.main button {
  width: 100%;
  font-size: 0.9rem !important;
}
