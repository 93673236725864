.main {
  height: calc(100% - 1px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: white;
  border-bottom: 1px solid var(--color-gray-2);
}

.wrapper {
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
}

.logo {
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-size: 3rem;
  cursor: pointer;
}

.logo a {
  color: var(--color-blue);
  text-decoration: none;
}

.menu {
  margin-left: auto;
}

.menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.menu ul li {
  float: left;
  margin-right: 20px;
}

.menu ul li:last-child {
  margin-right: 0px;
}

.menu ul li a {
  text-decoration: none;
  color: var(--color-gray-8);
}

.menu ul li a:hover {
  color: var(--color-primary);
}

.menu ul li button {
  background: transparent;
  border: none;
  outline: none;
  font-family: "Quicksand";
  color: var(--color-gray-8);
  font-size: 1rem;
  cursor: pointer;
}

.menu ul li button:hover {
  color: var(--color-primary);
}

.menu .accountBtn {
  font-size: 1.5rem;
  position: relative;
}

.menu .accountBtn .userMenu {
  position: absolute;
  background: white;
  width: 130px;
  margin: 0 auto;
  /* left: 50%; */
  right: 0;
  /* transform: translateX(-50%); */
  box-shadow: 0px 4px 6px -3px rgba(0, 0, 0, 0.4);
  border: 1px solid var(--color-gray-2);
  z-index: 2;
  top: calc(100% + 8px);
}

@media only screen and (max-width: 480px) {
  .main {
    padding: 0 8px;
  }
}
