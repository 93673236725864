.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.formMain {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    position: relative;
}

.error {
    position: absolute;
    background: rgba(255, 136, 136, 0.3);
    color: red;
    font-size: 0.8rem;
    text-align: center;
    width: 100%;
    padding: 5px 0;
    left: 0;
    bottom: 105%;
}

.message {
    position: absolute;
    background: rgba(150, 255, 136, 0.3);
    color: green;
    font-size: 0.8rem;
    text-align: center;
    width: 100%;
    padding: 5px 0;
    left: 0;
    bottom: 105%;
}

.formContainer {
    width: 100%;
}

.formContainer .title {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--color-blue);
    padding-bottom: 15px;
}

.formContainer form {
    width: 100%;
}

.formContainer form input {
    border: 1px solid var(--color-gray-2);
    padding: 12px 10px;
    width: calc(100% - 22px);
    margin-bottom: 10px;
    outline: none;
    font-family: 'Quicksand';
    color: var(--color-gray-6);
}

.formContainer form input:focus {
    border: 1px solid var(--color-primary);
}

.formContainer form .btn {
    text-align: center;
}

.formContainer form .btn button {
    border: none;
    outline: none;
    padding: 8px 32px;
    font-family: 'Quicksand';
    text-transform: uppercase;
    background: var(--color-blue);
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 1px;
}

.formContainer form .btn button:hover {
    background: var(--color-primary);
}

.formContainer .forgot {
    padding-bottom: 15px;
}

.formContainer .forgot a {
    font-style: italic;
    font-size: 0.8rem;
    color: var(--color-primary);
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 480px) {

    .main {
        width: calc(100% - 20px);
        padding: 0 10px;
    }

}