.container {
  padding-bottom: 24px;
  max-width: 1340px;
  margin: 0 auto;
}

.heading {
  font-size: 2rem;
  font-weight: bold;
  margin: 32px 0 20px 0;
  font-family: "Poppins";
}

.workshopsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 32px;
  column-gap: 32px;
}

.link {
  text-decoration: none;
}

@media only screen and (max-width: 480px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .workshopsContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}
