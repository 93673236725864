@import "../../../styles/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.5);
  position: relative;
  &:hover {
    .price {
      top: -6px;
      right: -1px;
    }
  }
}

.price {
  position: absolute;
  z-index: 1;
  padding: 4px 12px;
  font-size: 1.4rem;
  color: white;
  background: #ff6b5a;
  font-weight: 600;
  right: -5px;
  top: -10px;
  transform: skewX(-10deg);
  box-shadow: 1px 1px 10px -2px rgba(0, 0, 0, 0.5);
  transition: all ease-in-out 0.2s;
}

.imageContainer {
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center;
}
.imageContainer img {
  width: 100%;
  display: block;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px;
}

.workshopTitle {
  color: #464646;
  font-weight: 600;
  margin-bottom: 8px;
  font-family: "Montserrat";
  font-size: 1.6rem;
  @include multiLineEllipsis(1.8rem, 2);
}

.descriptionContainer {
  flex: 1;
}

.workshopDescription {
  color: #636363;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 1.5rem;
  font-family: "Montserrat";
  @include multiLineEllipsis(1.7rem, 3);
}

.timings {
  padding: 12px;
  padding-top: 0px;
  display: flex;
  align-items: center;
}

.date {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  fill: #636363;
}

.date span {
  margin-left: 4px;
  color: #505050;
  font-weight: 600;
}

.info {
  padding: 12px;
  border-top: 1px solid #f3f3f3;
  display: flex;
  align-items: center;
}

.seats {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: #636363;
}

.iconsContainer a {
  fill: unset;
}

.iconsContainer {
  justify-self: flex-end;
  fill: #636363 !important;
}

.iconsContainer:hover {
  fill: #4267b2 !important;
}
