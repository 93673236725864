.main {
    padding: 200px 0;
    background: url('https://source.unsplash.com/random/800x600');
    background-size: cover;
    background-position: center center;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.main::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
}

.content {
    position: absolute;
    z-index: 2;
    color: white;
    text-transform: uppercase;
    text-align: center;
}