.main {
    width: calc(100% - 40px);
    padding: 20px 20px;
    background: var(--color-blue);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: var(--color-primary);
}

.logo {
    letter-spacing: 5px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2rem;
    width: 100%;
    text-align: center;
}

.links {
    display: flex;
    flex-wrap: wrap;
    width: 33.33%;
}

.links .link {
    width: 50%;
    padding: 5px 0;
}

.links .link a {
    color: var(--color-primary);
    text-decoration: none;
}

.links .link a:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 480px) {
    .logo {
        width: 100%;
        text-align: center;
    }
    .links {
        width: 100%;
        text-align: center;
        padding: 15px 0;
    }
}