@mixin multiLineEllipsis(
  $lineHeight: 2.2rem,
  $lineCount: 1,
  $maxCharacter: default
) {
  display: block;
  display: -webkit-box;
  height: $lineHeight * $lineCount;
  line-height: $lineHeight;
  -webkit-line-clamp: $lineCount;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  @if $maxCharacter != default {
    max-width: #{$maxCharacter}ch;
  }
}
