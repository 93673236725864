:root {
  font-size: 10px;
  --color-heading: #2e2e2e;
  --color-gray-1: #f7f7f7;
  --color-gray-2: #d8d8d8;
  --color-gray-3: #c4c4c4;
  --color-gray-4: #a5a5a5;
  --color-gray-5: #7c7c7c;
  --color-gray-6: #5a5a5a;
  --color-gray-7: #4b4b4b;
  --color-gray-8: #353535;
  --color-gray-9: #1a1a1a;
  --color-gray-10: #161616;
  --color-primary: #d38cad;
  --color-primary-2: rgba(211, 140, 173, 0.5);
  --color-blue: #17466e;
}

body {
  margin: 0;
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 768px) {
  :root {
    font-size: 9px;
  }
}

@media only screen and (max-width: 480px) {
  :root {
    font-size: 8px;
  }
}
