.main {
  width: 100%;
  min-height: 100%;
}

.printCards {
  /* display: flex;
    flex-wrap: wrap; */
  padding: 20px 0;
}

.printCard {
  border: 1px solid var(--color-gray-2);
  width: calc(33.33% - 12px);
  margin: 0 5px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .main {
    width: calc(100% - 10px);
    padding: 0 5px;
  }
}

@media only screen and (max-width: 480px) {
  .main {
    width: calc(100% - 20px);
    padding: 0 10px;
  }
}
