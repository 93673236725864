.main {
    width: 100%;
    min-height: 100%;
    position: relative;
}

.form {
    width: calc(100% - 30px);
    max-width: 400px;
    position: relative;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
}

.error {
    position: absolute;
    background: rgba(255, 136, 136, 0.3);
    color: red;
    font-size: 0.8rem;
    text-align: center;
    width: 50%;
    max-width: 150px;
    padding: 5px 7px;
    right: 0;
    top: 0;
    z-index: 2;
}

.message {
    position: absolute;
    background: rgba(150, 255, 136, 0.3);
    color: green;
    font-size: 0.8rem;
    text-align: center;
    width: 100%;
    padding: 5px 0;
    left: 0;
    top: 0;
    z-index: 2;
}

.form input {
    border: 1px solid var(--color-gray-2);
    padding: 12px 10px;
    width: calc(100% - 22px);
    margin-bottom: 10px;
    outline: none;
    font-family: 'Quicksand';
    color: var(--color-gray-6);
}

.form input:focus {
    border: 1px solid var(--color-primary);
}

.form button {
    border: none;
    outline: none;
    padding: 8px 32px;
    font-family: 'Quicksand';
    text-transform: uppercase;
    background: var(--color-blue);
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 1px;
}

.form button:hover {
    background: var(--color-primary);
}

.title {
    font-weight: bold;
    text-transform: uppercase;
    color: var(--color-blue);
    padding: 0 15px;
    padding-top: 15px;
}