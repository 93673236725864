.main {
  width: 100%;
  min-height: 100%;
}

.main > .wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}

.main .galleryContainer {
  width: 55%;
}

.main .right {
  width: calc(45% - 20px);
  padding-left: 20px;
}

.main .right .printTitle {
  font-size: 1.4rem;
  text-transform: uppercase;
  color: var(--color-gray-8);
  letter-spacing: 1px;
  font-weight: 500;
  padding-bottom: 5px;
}

.main .right .price {
  font-size: 1.4rem;
  color: var(--color-gray-7);
  padding-bottom: 5px;
}

.main .right .description {
  font-size: 0.9rem;
  color: var(--color-gray-5);
  text-align: justify;
  padding: 5px 0;
  line-height: 1.4rem;
}

.main .right .sizeSelectContainer {
  padding: 5px 0;
  padding-top: 10px;
}

.main .right .sizeSelectContainer label {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.3rem;
  color: var(--color-gray-6);
  letter-spacing: 1px;
}

.main .right .sizeSelectContainer select {
  width: 100%;
  padding: 5px 0;
  background: white;
  color: var(--color-gray-7);
}

.main .right .btnsContainer {
  padding-top: 25px;
  padding-bottom: 40px;
}

.main .right .btnsContainer button {
  width: calc(50% - 10px);
  padding: 12px 0;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Teko";
  font-size: 1.3rem;
  letter-spacing: 1px;
}

.main .right .btnsContainer button:first-child {
  margin-right: 10px;
  background: white;
  border: 1px solid var(--color-blue);
  color: var(--color-blue);
}

.main .right .btnsContainer button:last-child {
  margin-left: 10px;
  background: var(--color-blue);
  color: white;
  border: 1px solid var(--color-blue);
}

.main .right .btnsContainer button:last-child:hover {
  margin-left: 10px;
  background: var(--color-primary-2);
  color: var(--color-blue);
}

.main .right .shareContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 15px;
}

.main .right .shareContainer .text {
  color: var(--color-gray-6);
  text-transform: uppercase;
  font-size: 0.9rem;
}

.main .right .shareContainer a {
  margin-left: 10px;
  border: 1px solid var(--color-gray-6);
  color: var(--color-gray-6);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all ease-in-out 0.1s;
}

.main .right .shareContainer a:hover {
  border: 1px solid var(--color-blue);
  color: var(--color-blue);
}

@media only screen and (max-width: 768px) {
  .main {
    width: calc(100% - 10px);
    padding: 0 5px;
  }
  .main .galleryContainer {
    width: 50%;
  }
  .main .right {
    width: calc(50% - 10px);
    padding-left: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .main {
    width: calc(100% - 20px);
    padding: 0 10px;
  }
  .main .galleryContainer {
    width: 100%;
  }
  .main .right {
    width: 100%;
    padding: 0;
    padding-top: 15px;
  }
  .main .right .shareContainer {
    justify-content: center;
  }
  .main .right .shareContainer > div,
  .main .right .shareContainer > a {
    margin: 0;
    margin: 0 5px;
  }
}
